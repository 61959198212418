import React, { useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import Config from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DinnerSeatingWithErrorMessage } from "../../interfaces";
import { DinnerTable } from "./DinnerTable";
import { useSnackbar } from "notistack";
import { viridis} from '../../utils/colours';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 20,
    },
    round: {
      marginBottom: 20,
    },
    tableContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fa897b",
      color: "#ffffff",
      borderRadius: 20,
      padding: 20,
    },
    container: {},
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    legendRow: {
      display: "flex",
      alignItems: "center",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    listContainer: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
    },
    colourContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    spacer: {
      flex: 1,
    },
  })
);

export const Dinner = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const [updatedData, setUpdatedData] = useState<any>(null);

  const accessRights = localStorage.getItem("access_rights");

  const [selectedRound, setSelectedRound] = useState<number>(1);

  const [showDetailedInformation, setShowDetailedInformation] = useState<boolean>(true);

  const processData = (data: any) => {
//    console.log("test");
//    console.log(data.error);
//    console.log(data.options);
//    console.log(data.seatings);

    if(data.error.error_found){
      enqueueSnackbar(
        data.error.error_message + "; " + data.error.solution_message,
        { variant: "error" }
      );
    }


    const rounds: any[] = [];
//    console.log(rounds);
    setUpdatedData(data);

//    setUpdatedData(data?.seatings);
    return data;

    };

  const { isLoading: isPostDinnerSeatingWithErrorMessageLoading, mutate } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/calculate_dinner_seating/`,
        {
          method: "POST",
          headers: headers,
        }
      ).then((res) => res.json())
       .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("dinner", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "An unhandled error occurred. Please contact the system administrator.",
          { variant: "error" }
        );
      },
    }
  );

  const { isLoading: isPostDinnerSeatingWithErrorMessageSingleLoading, mutate: mutateReseatSingle } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      const roundIndex = selectedRound - 1;

      console.log(selectedRound, roundIndex)

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/calculate_dinner_seating_by_round/${roundIndex}`,
        {
          method: "POST",
          headers: headers,
        }
      ).then((res) => res.json())
       .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("dinner", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "An unhandled error occurred. Please contact the system administrator.",
          { variant: "error" }
        );
      },
    }
  );

  const { isLoading: isPushLoading, mutate: mutatePush } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/manually_push_dinner_seating/`,
        {
          method: "POST",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("dinner", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to push manual seating to SF. Please contact system administrator",
          { variant: "error" }
        );
      },
    }
  );

  //fetching data from salesforce
  const { isLoading: isFetchLoading, mutate: mutateFetch } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/manually_fetch_dinner_seating/`,
        {
          method: "GET",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("dinner", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to fetch seating from SF. Please contact system administrator",
          { variant: "error" }
        );
      },
    }
  );


  const {
    isLoading: isGetDinnerSeatingWithErrorMessageLoading,
    data
  } = useQuery("dinner", () => {
    const baseURL = Config.getInstance().getCoordinationServiceURL();
    const eventID = Config.getInstance().getEventID();

    const headers: Headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${Config.getInstance().getToken()}`
    );

    let url = `${baseURL}/api/events/${eventID}/dinner_seating/`;

//   const ttest = fetch(url, {
//      headers: headers
//    });
//    console.log("ttest");
//    console.log(ttest.then((res) => res.json()));


    return fetch(url, {
      headers: headers
    })
      .then((res) => res.json())
      .then((data) => processData(data));
  });


  const { isLoading: isEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const isLoading =
    isPostDinnerSeatingWithErrorMessageLoading || isPostDinnerSeatingWithErrorMessageSingleLoading || isGetDinnerSeatingWithErrorMessageLoading || isEventLoading || isPushLoading || isFetchLoading;

  const seatingsByRound = updatedData?.seatings?.reduce(
    (acc: DinnerSeatingWithErrorMessage[][], DinnerSeatingWithErrorMessage: DinnerSeatingWithErrorMessage) => {
      const mutableAcc = [...acc];

      mutableAcc[DinnerSeatingWithErrorMessage.round] = mutableAcc[DinnerSeatingWithErrorMessage.round]
        ? [...mutableAcc[DinnerSeatingWithErrorMessage.round], DinnerSeatingWithErrorMessage]
        : [DinnerSeatingWithErrorMessage];

      return mutableAcc;
    },
    []
  );

  const rounds = seatingsByRound?.map((round: any) => {
    if(round !== undefined){
      const tables = (round as DinnerSeatingWithErrorMessage[]).reduce((acc, dinnerSeat) => {
        const mutableAcc: DinnerSeatingWithErrorMessage[][] = [...acc];
        mutableAcc[dinnerSeat.table_id] = mutableAcc[dinnerSeat.table_id]
          ? [...mutableAcc[dinnerSeat.table_id], dinnerSeat]
          : [dinnerSeat];
        return mutableAcc;
      }, [] as DinnerSeatingWithErrorMessage[][]);

      return tables;
    }
  });


  const sendDataToParent = (registration_id: string, seat_number: number) => {
    //get the registration_id and table-number from the child render (Table.tsx)
    //send it to API to save it in local database

     const baseURL = Config.getInstance().getCoordinationServiceURL();
     const eventID = Config.getInstance().getEventID();

     const headers = new Headers();
     headers.append("Accept", "application/json");
     headers.append("Content-Type", "application/json");

     headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

     const updatedRegistration = {registration_id: registration_id,
                                   round_number: selectedRound,
                                   new_seat_number: seat_number};


     return fetch(
        `${baseURL}/api/events/${eventID}/manually_update_dinner_seating/`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(updatedRegistration),
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
  };

  //colour ranges
  const cMin = 0.;
  const cMax = 4.;


  const colourLegend = () => {

    const nboxes = 9;
    let indices : number[] = new Array(nboxes);
    for(var i=0; i<indices.length;i++){
      indices[i]=i;
    }
    const boxWidth = 100./nboxes;

    return (
      <Box className={classes.row}>
         {indices.map((thisIndex: number) => {
           return(
             <Box className={classes.colourContainer}
                   style={{width: `${boxWidth}%`,
                           backgroundColor: `rgb(${viridis(cMin,cMax,cMin+(cMax-cMin)/(nboxes-1) * thisIndex)[0]},
                                                 ${viridis(cMin,cMax,cMin+(cMax-cMin)/(nboxes-1) * thisIndex)[1]},
                                                 ${viridis(cMin,cMax,cMin+(cMax-cMin)/(nboxes-1) * thisIndex)[2]})`}}
             >
               {cMin+(cMax-cMin)/(nboxes-1) * thisIndex > (cMax+cMin)/2 ?
                <Typography color="textPrimaryDark">
                   {Math.round(10*(cMin+(cMax-cMin)/(nboxes-1) * thisIndex))/10}
                </Typography>
                :
                <Typography color="textPrimary">
                   {Math.round(10*(cMin+(cMax-cMin)/(nboxes-1) * thisIndex))/10}
                </Typography>
              }
            </Box>
          )
         })
        }
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.controlContainer}>
        <Box className={classes.row} style={{ padding: 20 }}>
          <Box style={{ flex: 1 }}>
            <Typography color="textSecondary">{eventData?.name}</Typography>
          </Box>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
          <Box
            style={{
              flex: 1,
            }}
          >
          <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => setShowDetailedInformation(!showDetailedInformation)}
            >
              {showDetailedInformation === true ? "Hide additional Information" : "Display additional Information"}
          </Button>
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutateFetch()}
            >
              Fetch from SF
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutatePush()}
            >
              Push to SF
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              color="primary"
              variant="contained"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutate()}
            >
              Reseat
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              color="primary"
              variant="contained"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutateReseatSingle()}
            >
              Reseat this round
            </Button>
          }
          </Box>
          </Box>
        </Box>
        {isLoading && <LinearProgress color="primary" />}
      </Box>

      { showDetailedInformation === true && colourLegend() }


      <Box className={classes.listContainer} style={{ padding: 20 }}>
        <Box className={classes.selectionContainer}>
          <Box className={classes.roundSelectionContainer}>
              <Box sx={{width: 200}}>
                <ToggleButtonGroup
                  color="primary"
                  value={selectedRound}
                  exclusive
                  onChange={(_, value: number) => {
                    setSelectedRound(value || selectedRound);
                  }}
                  size="small"
                  className={classes.toggleGroup}
                >
                  <ToggleButton value={1} className={classes.toggleButton}>
                    Round 1
                  </ToggleButton>
                  <ToggleButton value={2} className={classes.toggleButton}>
                    Round 2
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            <Box className={classes.spacer} />
            {showDetailedInformation === true &&
              <>
                <Box className={classes.column} style={{ marginRight: 20 }}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    {updatedData?.["options"]?.[selectedRound - 1]?.["table_count"]}
                  </Typography>
                  <Typography align="left" color="textSecondary">
                    Tables
                  </Typography>
                </Box>
                <Box className={classes.column}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    {
                      updatedData?.["options"]?.[selectedRound - 1]?.[
                        "seats_per_table_count"
                      ]
                    }
                  </Typography>
                  <Typography align="left" color="textSecondary">
                    Seats per table
                  </Typography>
               </Box>
               </>
              }
              <Box className={classes.spacer} />
              <Box sx={{width: 200}}/>
          </Box>
        </Box>
        {(rounds?.[selectedRound - 1] || []).map(
          (dinnerSeats: any, index: number) => (
            <Box key={index} className={classes.round}>
              <Box className={classes.tableContainer}>
                <DinnerTable
                  accessRights={accessRights || "null"}
                  dinnerSeatingsWithErrorMessage={dinnerSeats}
                  sendDataToParent={sendDataToParent}
                  showDetailedInformation={showDetailedInformation}
                  tableLength={
                    updatedData?.["options"]?.[selectedRound - 1]?.[
                      "seats_per_table_count"
                    ] || 8
                  }
                />
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
