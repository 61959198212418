import React, { useState } from "react";

import {
  Box,
  Button,
  createStyles,
    IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import Logo from "../../resources/logo.svg";
import Config from "../../config";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 20000,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      paddingBottom: 20,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      overflow: "hidden",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
    },
    textField: {
      marginBottom: 20,
      backgroundColor: theme.palette.background.default,
    },
    logo: {
      height: 20,
      marginBottom: 40,
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      marginBottom: 20,
    },
    error: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      borderRadius: 10,
      marginBottom: 20,
    },
  })
);

export function Authentication() {
  const classes = useStyles();

  const history = useHistory();

  const [password, setPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loginRequest = (data: { username: string; password: string }) => {
    const headers: Headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    return fetch(
      `${Config.getInstance().getCoordinationServiceURL()}/api/login/`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: data["username"],
          password: data["password"],
        }),
      }
    ).then((response) => {
      if (!response.ok) throw new Error("Authorization failed");
      return response.json();
    });
  };

  const loginMutation = useMutation(loginRequest, {
    onSuccess: (data) => {
      localStorage.setItem("access_token", data["access_token"]);
      localStorage.setItem("access_rights", data["access_rights"]);
      localStorage.setItem("sse_access_token", data["sse_access_token"]);

      const urlSearchParams = new URLSearchParams(window.location.search);

      const redirectURL = urlSearchParams.get("next") || "";

      urlSearchParams.delete("next");

      history.push({
        pathname: redirectURL ? redirectURL : "/think-tanks/",
        search: urlSearchParams.toString(),
      });
    },
  });

  const { error, isLoading } = loginMutation;

  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    loginMutation.mutate({
      username: Config.getInstance().getEventID(),
      password: password,
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        {isLoading && <LinearProgress color="primary" />}
        <Box className={classes.cardContainer}>
          <Box className={classes.logoContainer}>
            <img alt="logo" className={classes.logo} src={Logo} />
          </Box>
          <Typography
            color="textSecondary"
            align="center"
            className={classes.text}
          >
            Please provide the date of the event to verify your identity
            (dd.MM.YYYY)
          </Typography>
          {error && (
            <Box className={classes.error}>
              <Typography color="textPrimary">
                Incorrect username or password
              </Typography>
            </Box>
          )}
          <TextField
            size="small"
            label="Event date"
            variant="filled"
            type={showPassword ? "text" : "password"}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword &&
                            <VisibilityRoundedIcon/>
                        }
                        {!showPassword &&
                            <VisibilityOffRoundedIcon/>
                        }
                    </IconButton>
                </InputAdornment>
              ),
            }}
            className={classes.textField}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={onLoginButtonClick}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
