import React, { useState, useEffect } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import { Table } from "./Table";
import Config from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { Participant, ParticipantWithErrorMessage } from "../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 20,
    },
    round: {
      marginBottom: 20,
    },
    tableContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fa897b",
      color: "#ffffff",
      borderRadius: 20,
      padding: 20,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    legendRow: {
      display: "flex",
      alignItems: "center",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    indicator: {
      height: 14,
      width: 14,
      borderRadius: 7,
      marginRight: 7,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    spacer: {
      flex: 1,
    },
  })
);

export const ThinkTanks = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const [updatedData, setUpdatedData] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState({"error_found": false,
                                                    "error_message": ""})

  const [showDetailedInformation, setShowDetailedInformation] = useState<boolean>(true);

  const accessRights = localStorage.getItem("access_rights");

  const [selectedRound, setSelectedRound] = useState<number>(1);

  const processData = (data: any) => {

    const rounds: any[] = [];

    if(data["error_found"] === true){
        enqueueSnackbar(
          data["error_message"],
          { variant: "error" }
        );
        setUpdatedData(rounds);

    return rounds;

    };

    const thinkTanksByID = data["think_tanks"].reduce(
      (acc: any, datum: any) => ({
        ...acc,
        [datum.think_tank_number]: {
          ...datum,
          registrations: [],
        },
      }),
      {} as { [key: string]: any }
    );

    const thinkTankRealTablesByID = data["think_tank_real_tables"].reduce(
      (acc: any, datum: any) => ({
        ...acc,
        [datum.think_tank_real_table_index]: {
          ...datum,
          registrations: [],
        },
      }),
      {} as { [key: string]: any }
    );


/*    for (const registration of data["registrations"]) {
      thinkTanksByID[registration["think_tank_number"]].registrations.push(
        registration
      );
    } */
    for (const registration of data["registrations"]) {
      thinkTankRealTablesByID[registration["think_tank_real_table_index"]].registrations.push(registration);
    }

/*    Object.values(thinkTanksByID).forEach((thinkTank: any) => {
      rounds[thinkTank.round] = [...(rounds[thinkTank.round] || []), thinkTank];
    });*/

    Object.values(thinkTankRealTablesByID).forEach((thinkTankRealTable: any) => {
      rounds[thinkTankRealTable.round_number-1] = [...(rounds[thinkTankRealTable.round_number-1] || []), thinkTankRealTable];
    });

    rounds[2]=data["objective"];

    rounds[3] = data["weight_map"];

//initialize the updatedData array (which will be the one actually being displayed)
    setUpdatedData(rounds);

    return rounds;
  };

  const { isLoading: isGetLoading, data } = useQuery("think-tanks", () => {
    const baseURL = Config.getInstance().getCoordinationServiceURL();
    const eventID = Config.getInstance().getEventID();

    const headers: Headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    let url = `${baseURL}/api/events/${eventID}/think_tank_seating/`;

    console.log(baseURL);
    console.log(Config.getInstance().getToken());

    headers.append(
      "Authorization",
      `Bearer ${Config.getInstance().getToken()}`
    );

    return fetch(url, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => processData(data));
  });

  const { isLoading: isPostLoading, mutate } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/calculate_think_tank_seating/`,
        {
          method: "POST",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("think-tanks", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to calculate think tank seating. Please make sure all participants have submitted their priorities.",
          { variant: "error" }
        );
      },
    }
  );


  const { isLoading: isPostLoadingSingle, mutate: mutateReseatSingle } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      const roundIndex = selectedRound - 1;

//      console.log(selectedRound, roundIndex)

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/calculate_think_tank_seating_by_round/${roundIndex}`,
        {
          method: "POST",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("think-tanks", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to calculate think tank seating. Please make sure all participants have submitted their priorities.",
          { variant: "error" }
        );
      },
    }
  );


  const { isLoading: isPushLoading, mutate: mutatePush } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/manually_push_think_tank_seating/`,
        {
          method: "POST",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("think-tanks", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to push manual seating to SF. Please contact system administrator",
          { variant: "error" }
        );
      },
    }
  );

  const { isLoading: isFetchLoading, mutate: mutateFetch } = useMutation(
    () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/manually_fetch_think_tank_seating/`,
        {
          method: "GET",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("think-tanks", () => data);
      },
      onError: (error) => {
        enqueueSnackbar(
          "Failed to fetch seating from SF. Please contact system administrator",
          { variant: "error" }
        );
      },
    }
  );

  const { isLoading: isEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const isLoading = isPostLoading || isGetLoading || isEventLoading || isPushLoading || isFetchLoading || isPostLoadingSingle;

  const numberOfTables = (data?.[selectedRound -1 ] || []).filter(
    (thinkTankRealTable: any) => thinkTankRealTable.registrations.length > 0
  ).length;

  const numberOfTopics = Object.keys(
    (data?.[selectedRound -1 ] || [])
      .filter((thinkTankRealTable: any) => thinkTankRealTable.registrations.length > 0)
      .reduce(
        (acc: { [key: string]: boolean }, thinkTankRealTable: any) => ({
          ...acc,
          [thinkTankRealTable.think_tank_table_index]: true,
        }),
        {}
      )
  ).length;



  const chairsPerTable = (updatedData?.[selectedRound - 1] || [])
    .filter((thinkTankRealTable: any) => thinkTankRealTable.registrations.length > 0)
    .map((thinkTankRealTable: any) => thinkTankRealTable.registrations.length)
    .sort((first: number, second: number) => first - second);

  const objective = (updatedData?.[2] || 0.);
  const weight_map = (updatedData?.[3] || {'Prio1': 2.0,
                                           'Prio2': 1.0,
                                           'Prio3': 0.5,
                                           'Prio4': 0.25});



//create a callback function to update the registration when being reseated manually

  const sendDataToParent = (registration_id: string, table_number: number) => {
    //get the registration_id and table-number from the child render (Table.tsx)
    //send it to API to save it in local database

     const baseURL = Config.getInstance().getCoordinationServiceURL();
     const eventID = Config.getInstance().getEventID();

     const headers = new Headers();
     headers.append("Accept", "application/json");
     headers.append("Content-Type", "application/json");

     headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

     const updatedRegistration = {registration_id: registration_id,
                                   round_number: selectedRound,
                                   new_table_number: table_number};

     return fetch(
        `${baseURL}/api/events/${eventID}/manually_update_think_tank_seating/`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(updatedRegistration),
        }
      )
        .then((res) => res.json())
        .then((data) => processData(data));

  };


  return (
    <Box className={classes.container}>
      <Box className={classes.controlContainer}>
        <Box className={classes.row} style={{ padding: 20 }}>
          <Box style={{ flex: 1 }}>
            <Typography color="textSecondary">{eventData?.name}</Typography>
          </Box>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
          <Box
            style={{
              flex: 1,
            }}
          >
          <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => setShowDetailedInformation(!showDetailedInformation)}
            >
              {showDetailedInformation === true ? "Hide additional Information" : "Display additional Information"}
          </Button>
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutateFetch()}
            >
              Fetch from SF
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutatePush()}
            >
              Push to SF
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutate()}
            >
              Reseat
            </Button>
          }
          {accessRights === "full" && showDetailedInformation === true &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              endIcon={<ReplayRoundedIcon />}
              onClick={() => mutateReseatSingle()}
            >
              Reseat this round
            </Button>
          }
          </Box>
          </Box>
        </Box>
        {isLoading && <LinearProgress color="primary" />}
      </Box>
      {!isGetLoading && (
        <Box className={classes.listContainer} style={{ padding: 20 }}>
          <Box className={classes.round}>
            <Box className={classes.selectionContainer}>
              <Box className={classes.roundSelectionContainer}>
                  <Box sx={{width: 200}}>
                <ToggleButtonGroup
                  color="primary"
                  value={selectedRound}
                  exclusive
                  onChange={(_, value: number) => {
                    setSelectedRound(value || selectedRound);
                  }}
                  size="small"
                  className={classes.toggleGroup}
                >
                  <ToggleButton value={1} className={classes.toggleButton}>
                    Round 1
                  </ToggleButton>
                  <ToggleButton value={2} className={classes.toggleButton}>
                    Round 2
                  </ToggleButton>
                </ToggleButtonGroup>
                  </Box>
                <Box className={classes.spacer} />
                {showDetailedInformation === true &&
                  <Box className={classes.column} style={{ marginRight: 20 }}>
                    <Typography
                      align="left"
                      color="textPrimary"
                      style={{ fontWeight: "bold" }}
                    >
                      {objective}
                    </Typography>
                    <Typography align="left" color="textSecondary">
                      Objective
                    </Typography>
                  </Box>
                }

                {showDetailedInformation === true &&
                  <Box className={classes.column} style={{ marginRight: 20 }}>
                    <Typography
                      align="left"
                      color="textPrimary"
                      style={{ fontWeight: "bold" }}
                    >
                      {numberOfTopics}
                    </Typography>
                    <Typography align="left" color="textSecondary">
                      Topics
                    </Typography>
                  </Box>
                }

                {showDetailedInformation === true &&
                  <Box className={classes.column} style={{ marginRight: 20 }}>
                    <Typography
                      align="left"
                      color="textPrimary"
                      style={{ fontWeight: "bold" }}
                    >
                      {numberOfTables}
                    </Typography>
                    <Typography align="left" color="textSecondary">
                      Tables
                    </Typography>
                  </Box>
                }

                {showDetailedInformation === true &&
                  <Box className={classes.column}>
                    <Typography
                      align="left"
                      color="textPrimary"
                      style={{ fontWeight: "bold" }}
                    >
                      {chairsPerTable.length < 1
                        ? "-"
                        : chairsPerTable[0] ===
                          chairsPerTable[chairsPerTable.length - 1]
                        ? chairsPerTable[0]
                        : `${chairsPerTable[0]} - ${
                            chairsPerTable[chairsPerTable.length - 1]
                          }`}
                    </Typography>
                    <Typography align="left" color="textSecondary">
                      Seats per table
                    </Typography>
                  </Box>
                }
              <Box className={classes.spacer} />
                  <Box sx={{width: 200}}/>
              </Box>
            </Box>
            <Box className={classes.tableContainer}>
              {(updatedData?.[selectedRound - 1] || [])
                .filter((thinkTankRealTable: any) => {
                  if(showDetailedInformation === true){
                     return thinkTankRealTable.registrations.length > 0
                  } else {
                    return thinkTankRealTable.registrations.length > 0 && thinkTankRealTable.think_tank_title !== "Not seated"
                  }

                })
                .sort((thinkTankRealTableFirst: any, thinkTankRealTableSecond: any) => {
                    if (thinkTankRealTableFirst.table_number > thinkTankRealTableSecond.table_number){
                      return 1;
                    }
                    if (thinkTankRealTableFirst.table_number < thinkTankRealTableSecond.table_number){
                      return -1;
                    }
                    return 0;
                })
                .map((thinkTankRealTable: any) => (
                  <Table
                    id={thinkTankRealTable.id}
                    access_rights={accessRights || "null"}
                    showDetailedInformation={showDetailedInformation}
                    weight_map={weight_map}
                    key={thinkTankRealTable.id}
                    name={thinkTankRealTable.think_tank_name}
                    tableNumber={thinkTankRealTable.table_number}
                    bookedTable={thinkTankRealTable.business_partner_id ? true : false}
                    sendDataToParent={sendDataToParent}
                    title={thinkTankRealTable.think_tank_title}
                    topic={thinkTankRealTable.topic}
                    subTopic={thinkTankRealTable.sub_topic}
                    subSubTopic={thinkTankRealTable.sub_sub_topic}
                    registrations={thinkTankRealTable.registrations.map(
                      (registration: any) => ({
                        id: registration["registration_id"],
                        name: registration["registration_name"],
                        title: registration["registration_title"],
                        company: registration["registration_company"],
                        weight: registration["weight"],
                        type: registration["type"],
                        priorities: [registration["think_tank_id_prio1"],
                                     registration["think_tank_id_prio2"],
                                     registration["think_tank_id_prio3"],
                                     registration["think_tank_id_prio4"]],
                        related_errors: registration["related_errors"]
                               .filter((error_message: any) => error_message["type"] === "ThinkTankSeatingError")
                               .map(
                          (error_message: any) => (
                             error_message["error_message"]
                        )
                      ),
                        related_error_severities: registration["related_errors"]
                               .filter((error_message: any) => error_message["type"] === "ThinkTankSeatingError")
                               .map(
                          (error_message: any) => (
                             error_message["error_severity"]
                        )
                      )
                      })
                    )}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
