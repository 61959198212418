import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  createStyles,
//  IconButton,
  makeStyles,
  Theme,
//  TextField,
//  InputAdornment,
  Typography,
  Divider,
  Slider,
  Switch,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
//import CropFreeRoundedIcon from "@material-ui/icons/CropFreeRounded";
//import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Config from "../../config";
import { SettingsJSON } from "../../interfaces";
//import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
//import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: "column",
      display: "flex",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },

    searchFieldContainer: {
      padding: 20,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    scrollContainer: {
      paddingBottom: 20,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
/*    sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
    },*/
sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      height: 100,
      width: 300,
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      borderRadius: 10,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
  })
);


function potentialFunction(value: number){
  const base = 10.;
  return base**value;
}

function potentialFunctionFormat(value: number){
  let scaledValue = value;
  let postfix = ''
  if(scaledValue > 100 && scaledValue < 1000000){
    postfix = 'k'
    scaledValue = scaledValue/1000.
  }
  if(scaledValue > 100000){
    postfix = 'm'
    scaledValue = scaledValue/1000000.
  }
  return `${scaledValue} ${postfix}`;
}

export const Settings = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const accessRights = localStorage.getItem("access_rights");

  const { isLoading: isRetrieveSettingsLoading, data: settingsData } = useQuery(
    "settings",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/settings/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const { isLoading: isUpdateSettingsLoading, mutate } = useMutation(
    ({
      new_cfac,
      new_chair_count,
      new_table_count,
      new_omit_opt_table_count,
      new_allow_mixed_bp_tt,
      new_allow_split_bp_tt,
      new_allow_competitor_bp_tt,
      new_fix_business_partners,
      new_fix_real_tables,
      new_table_count_dinner,
      new_include_tight_layout,
      new_include_close_layout,
      new_include_wide_layout,
      new_include_independent_layout,
      new_use_random_scheme,
      new_max_runtime,
      new_max_layout_combinations,
      new_seat_number_threshold,
      new_group_number,
      settings,
    }: {
      new_cfac: {value: number, changed: boolean};
      new_chair_count: {value: number[], changed: boolean};
      new_table_count: {value: number, changed: boolean};
      new_omit_opt_table_count: {value: boolean, changed: boolean};
      new_allow_mixed_bp_tt: {value: boolean, changed: boolean};
      new_allow_split_bp_tt: {value: boolean, changed: boolean};
      new_allow_competitor_bp_tt: {value: boolean, changed: boolean};
      new_fix_business_partners: {value: boolean, changed: boolean};
      new_fix_real_tables: {value: boolean, changed: boolean};
      new_table_count_dinner: {value: number, changed: boolean};
      new_include_tight_layout: {value: boolean, changed: boolean};
      new_include_close_layout: {value: boolean, changed: boolean};
      new_include_wide_layout: {value: boolean, changed: boolean};
      new_include_independent_layout: {value: boolean, changed: boolean};
      new_use_random_scheme: {value: boolean, changed: boolean};
      new_max_runtime: {value: number, changed: boolean};
      new_max_layout_combinations: {value: number, changed: boolean};
      new_seat_number_threshold: {value: number, changed: boolean};
      new_group_number: {value: number, changed: boolean};
      settings: SettingsJSON;
    }) => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );


      new_cfac.changed === true
        ? settings.settings_tt_cfac = new_cfac.value
        : console.log()

      new_chair_count.changed === true
        ? settings.settings_tt_chair_count_min = new_chair_count.value[0]
        : console.log()

      new_chair_count.changed === true
        ? settings.settings_tt_chair_count_max = new_chair_count.value[1]
        : console.log()

      new_table_count.changed === true
        ? settings.settings_tt_table_count = new_table_count.value
        : console.log()

      new_omit_opt_table_count.changed === true
        ? settings.settings_tt_omit_opt_table_count = new_omit_opt_table_count.value
        : console.log()

      new_allow_mixed_bp_tt.changed === true
        ? settings.settings_tt_allow_mixed_bp_tt = new_allow_mixed_bp_tt.value
        : console.log()

      new_allow_split_bp_tt.changed === true
        ? settings.settings_tt_allow_split_bp_tt = new_allow_split_bp_tt.value
        : console.log()

      new_allow_competitor_bp_tt.changed === true
        ? settings.settings_tt_allow_competitor_bp_tt = new_allow_competitor_bp_tt.value
        : console.log()

      new_fix_business_partners.changed === true
        ? settings.settings_tt_fix_business_partners = new_fix_business_partners.value
        : console.log()

      new_fix_real_tables.changed === true
        ? settings.settings_tt_fix_real_tables = new_fix_real_tables.value
        : console.log()

      new_table_count_dinner.changed === true
        ? settings.settings_dinner_table_count = new_table_count_dinner.value
        : console.log()

      new_include_tight_layout.changed === true
        ? settings.settings_dinner_include_tight_layout = new_include_tight_layout.value
        : console.log()

      new_include_close_layout.changed === true
        ? settings.settings_dinner_include_close_layout = new_include_close_layout.value
        : console.log()

      new_include_wide_layout.changed === true
        ? settings.settings_dinner_include_wide_layout = new_include_wide_layout.value
        : console.log()

      new_include_independent_layout.changed === true
        ? settings.settings_dinner_include_independent_layout = new_include_independent_layout.value
        : console.log()

      new_use_random_scheme.changed === true
        ? settings.settings_dinner_use_random_scheme = new_use_random_scheme.value
        : console.log()

      new_max_runtime.changed === true
        ? settings.settings_dinner_max_runtime = new_max_runtime.value
        : console.log()

      new_max_layout_combinations.changed === true
        ? settings.settings_dinner_max_layout_combinations = new_max_layout_combinations.value
        : console.log()

      new_seat_number_threshold.changed === true
        ? settings.settings_dinner_seat_number_threshold = new_seat_number_threshold.value
        : console.log()

      new_group_number.changed === true
        ? settings.settings_dinner_group_number = new_group_number.value
        : console.log()


      return fetch(
        `${baseURL}/api/events/${eventID}/settings/update/`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(settings),
        }
      ).then((res) => res.json());
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("settings", (settings) => {
          const castedData = data as SettingsJSON;
/*          const castedRegistrations = registrations as SettingsJSON; */

          enqueueSnackbar("Settings successfully updated.", {
            variant: "success",
          });

          return castedData;
        });
      },
    }
  );

  const { isLoading: isRetrieveEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const settings = (settingsData || {}) as SettingsJSON;

  const [cfac, setCfac] = React.useState({value: -10,
                                          changed: false});
  const [chair_count, setChairCount] = React.useState({value: [1,10],
                                                       changed: false});
  const [table_count, setTableCount] = React.useState({value: 10,
                                                       changed: false});
  const [omit_opt_table_count, setOmitOptTableCount] = useState({value: false,
                                                                 changed: false});
  const [allow_mixed_bp_tt, setAllowMixedBpTT] = useState({value: false,
                                                           changed: false});
  const [allow_split_bp_tt, setAllowSplitBpTT] = useState({value: false,
                                                           changed: false});
  const [allow_competitor_bp_tt, setAllowCompetitorBpTT] = useState({value: false,
                                                                     changed: false});
  const [fix_business_partners, setFixBusinessPartners] = useState({value: false,
                                                           changed: false});
  const [fix_real_tables, setFixRealTables] = useState({value: false,
                                                           changed: false});
  const [table_count_dinner, setTableCountDinner] = React.useState({value: 1,
                                                       changed: false});
  const [include_tight_layout, setTightLayout] = React.useState({value: false,
                                                       changed: false});
  const [include_close_layout, setCloseLayout] = React.useState({value: false,
                                                       changed: false});
  const [include_wide_layout, setWideLayout] = React.useState({value: false,
                                                       changed: false});
  const [include_independent_layout, setIndependentLayout] = React.useState({value: false,
                                                       changed: false});
  const [use_random_scheme, setUseRandomScheme] = React.useState({value: false,
                                                                  changed: false});
  const [max_runtime, setMaxRuntime] = React.useState({value: 180,
                                                       changed: false});
  const [max_layout_combinations, setMaxLayoutCombinations] = React.useState({value: 4,
                                                              changed: false});
  const [seat_number_threshold, setSeatNumberThreshold] = React.useState({value: 0,
                                                                          changed: false});
  const [group_number, setGroupNumber] = React.useState({value: 0,
                                                       changed: false});


  const isLoading =
    isRetrieveSettingsLoading ||
    isUpdateSettingsLoading ||
    isRetrieveEventLoading;

  useEffect(() => {
    !isLoading
       ? setOmitOptTableCount({value: settings.settings_tt_omit_opt_table_count,
                               changed: false})
       : setOmitOptTableCount({value: omit_opt_table_count.value,
                               changed: false})
  }, [isLoading])


  return (
    <Box className={classes.container}>
    <Box className={classes.controlContainer}>
      <Box className={classes.row} style={{ padding: 20 }}>
        <Box style={{ flex: 1 }}>
          <Typography color="textSecondary">{eventData?.name}</Typography>
        </Box>
        <Box
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
    <Button
      color="primary"
      variant="contained"
      disabled={accessRights === "full" ? isLoading :
                accessRights === "read_only" ? true :
                accessRights === "limited" ? true : true}
      endIcon={<ReplayRoundedIcon />}
      onClick={() => mutate({ new_cfac: cfac,
                              new_chair_count: chair_count,
                              new_table_count: table_count,
                              new_omit_opt_table_count: omit_opt_table_count,
                              new_allow_mixed_bp_tt: allow_mixed_bp_tt,
                              new_allow_split_bp_tt: allow_split_bp_tt,
                              new_allow_competitor_bp_tt: allow_competitor_bp_tt,
                              new_fix_business_partners: fix_business_partners,
                              new_fix_real_tables: fix_real_tables,
                              new_table_count_dinner: table_count_dinner,
                              new_include_tight_layout: include_tight_layout,
                              new_include_close_layout: include_close_layout,
                              new_include_wide_layout: include_wide_layout,
                              new_include_independent_layout: include_independent_layout,
                              new_use_random_scheme: use_random_scheme,
                              new_max_runtime: max_runtime,
                              new_max_layout_combinations: max_layout_combinations,
                              new_seat_number_threshold: seat_number_threshold,
                              new_group_number: group_number,
                              settings: settings })
            }
    >
      Save
    </Button>
        </Box>
      </Box>
      {isLoading && <LinearProgress color="primary" />}
    </Box>

    <Box className={classes.listContainer} style={{ padding: 20 }}>


      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Cfac
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_cfac"
          onChange={(_, value) => setCfac({value: value as number, changed: true})}
          marks
          min={-1}
          max={1}
          step={0.01}
          valueLabelDisplay="on"
          defaultValue={settings.settings_tt_cfac}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true :
                    true }
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Min/Max number of chairs per table
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_chair_count"
          onChange={(_, value) => setChairCount({value: value as number[], changed: true})}
          marks
          min={0}
          max={10}
          step={1}
          valueLabelDisplay="on"
          defaultValue= {[settings.settings_tt_chair_count_min,settings.settings_tt_chair_count_max]}
          disabled = {accessRights === "full" && omit_opt_table_count.value ? false :
                      accessRights === "read_only" ? true :
                      accessRights === "limited" ? true : true}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Number of tables per round
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_table_count"
          onChange={(_, value) => setTableCount({value: value as number, changed: true})}
          marks
          min={0}
          max={10}
          step={1}
          valueLabelDisplay="on"
          defaultValue= {settings.settings_tt_table_count}
          disabled = {accessRights === "full" && omit_opt_table_count.value ? false :
                      accessRights === "read_only" ? true :
                      accessRights === "limited" ? true : true}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Omit optimization on table and chair count
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="omit_opt_table_count"
          disabled={accessRights === "full" ? false :
                      accessRights === "read_only" ? true :
                      accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_omit_opt_table_count}
          onChange={(_, value) => setOmitOptTableCount({value: value as boolean,
                                                        changed: true})}
        />
        )}
      </Box>


      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Allow Mixed Business Partners
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="allow_mixed_bp_tt_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_allow_mixed_bp_tt}
          onChange={(_, value) => setAllowMixedBpTT({value: value as boolean,
                                                     changed: true})}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Allow Split Business Partners
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="allow_split_bp_tt_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_allow_split_bp_tt}
          onChange={(_, value) => setAllowSplitBpTT({value: value as boolean,
                                                     changed: true})}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Allow Competitors at Think Tank Tables
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="allow_competitor_bp_tt_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_allow_competitor_bp_tt}
          onChange={(_, value) => setAllowCompetitorBpTT({value: value as boolean,
                                                          changed: true})}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Fix Business Partner Seating (from previous run)
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="fix_business_partners_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_fix_business_partners}
          onChange={(_, value) => setFixBusinessPartners({value: value as boolean,
                                                     changed: true})}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Fix TT Real Tables (from previous run)
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="fix_business_partners_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_tt_fix_real_tables}
          onChange={(_, value) => setFixRealTables({value: value as boolean,
                                                     changed: true})}
        />
        )}
      </Box>


      <Divider style={{marginTop: 10,
                       marginBottom: 10}}/>
      <Box className={classes.row}>
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Number of tables per round (Dinner)
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_table_count_dinner"
          onChange={(_, value) => setTableCountDinner({value: value as number, changed: true})}
          marks
          min={1}
          max={5}
          step={1}
          valueLabelDisplay="on"
          defaultValue= {settings.settings_dinner_table_count}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Seat Number Threshold (Dinner)
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_seat_number_threshold"
          onChange={(_, value) => setSeatNumberThreshold({value: value as number, changed: true})}
          marks
          min={1}
          max={201}
          step={10}
          valueLabelDisplay="on"
          defaultValue= {settings.settings_dinner_seat_number_threshold}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
        />
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Group Number
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_group_number"
          onChange={(_, value) => setGroupNumber({value: value as number, changed: true})}
          marks
          min={1}
          max={3}
          step={1}
          valueLabelDisplay="on"
          defaultValue= {settings.settings_dinner_group_number}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
        />
        )}
      </Box>


      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Maximum allowed runtime of dinner optimization (can still be longer due to a potentially large number of layout combinations)
        </Typography>
        {max_runtime.changed &&
        <Typography
          align="center"
          color="textSecondary"
          className={classes.name}>
           &nbsp; ({max_runtime.value} sec; {(max_runtime.value/60.).toFixed(2)} min)
        </Typography>
        }
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_max_runtime_dinner"
          onChange={(_, value) => setMaxRuntime({value: value as number, changed: true})}
          marks
          min={1}
          max={180}
          step={1}
          valueLabelDisplay="on"
          defaultValue= {settings.settings_dinner_max_runtime}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
        />
        )}
      </Box>


      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Limit number of layout combinations (e.g., if calculation takes forever although the maximum runtime is limited)
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Slider
          aria-label="slider_max_layout_combinations_dinner"
          onChange={(_, value) => setMaxLayoutCombinations({value: value as number, changed: true})}
          marks
          min={1}
          max={6}
          step={1}
          scale={potentialFunction}
          valueLabelDisplay="on"
          valueLabelFormat={potentialFunctionFormat}
          defaultValue= {settings.settings_dinner_max_layout_combinations}
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
        />
        )}
      </Box>


      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Include Table Layouts
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
          <FormGroup>
             <FormControlLabel
               control={
                  <Switch
                    aria-label="include_tight_layout_switch"
                    defaultChecked={settings.settings_dinner_include_tight_layout}
                    onChange={(_, value) => setTightLayout({value: value as boolean,
                                                            changed: true})}
                  />
               }
             label={<Typography color="textPrimary" className={classes.name}> tight layout </Typography>}
             labelPlacement="top"
            />
        </FormGroup>
        )}
        {!isRetrieveSettingsLoading && (
          <FormGroup>
             <FormControlLabel
               control={
                  <Switch
                    aria-label="include_close_layout_switch"
                    disabled={accessRights === "full" ? false :
                              accessRights === "read_only" ? true :
                              accessRights === "limited" ? true : true}
                    defaultChecked={settings.settings_dinner_include_close_layout}
                    onChange={(_, value) => setCloseLayout({value: value as boolean,
                                                            changed: true})}
                  />
               }
             label={<Typography color="textPrimary" className={classes.name}> close layout </Typography>}
             labelPlacement="top"
            />
        </FormGroup>
        )}
        {!isRetrieveSettingsLoading && (
          <FormGroup>
             <FormControlLabel
               control={
                  <Switch
                    aria-label="include_wide_layout_switch"
                    disabled={accessRights === "full" ? false :
                              accessRights === "read_only" ? true :
                              accessRights === "limited" ? true : true}
                    defaultChecked={settings.settings_dinner_include_wide_layout}
                    onChange={(_, value) => setWideLayout({value: value as boolean,
                                                            changed: true})}
                  />
               }
             label={<Typography color="textPrimary" className={classes.name}> wide layout </Typography>}
             labelPlacement="top"
            />
        </FormGroup>
        )}
        {!isRetrieveSettingsLoading && (
          <FormGroup>
             <FormControlLabel
               control={
                  <Switch
                    aria-label="include_independent_layout_switch"
                    disabled={accessRights === "full" ? false : true}
                    defaultChecked={settings.settings_dinner_include_independent_layout}
                    onChange={(_, value) => setIndependentLayout({value: value as boolean,
                                                            changed: true})}
                  />
               }
             label={<Typography color="textPrimary" className={classes.name}> independent layout </Typography>}
             labelPlacement="top"
            />
        </FormGroup>
        )}
      </Box>

      <Box className={classes.selectionContainer}>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.name}>
          Use Random Scheme for Optimization (e.g., if #BP representatives greater 10):
        </Typography>
      </Box>
      <Box className={classes.row}>
        {!isRetrieveSettingsLoading && (
        <Switch
          aria-label="use_random_scheme_switch"
          disabled={accessRights === "full" ? false :
                    accessRights === "read_only" ? true :
                    accessRights === "limited" ? true : true}
          defaultChecked={settings.settings_dinner_use_random_scheme}
                    onChange={(_, value) => setUseRandomScheme({value: value as boolean,
                                                             changed: true})}
        />
        )}
      </Box>



    </Box>

  </Box>
  );
};
